import React from "react";
import PickMeals from "../Assets/pick-meals-image.png";
import ChooseMeals from "../Assets/choose-image.png";
import DeliveryMeals from "../Assets/delivery-image.png";
import { useTranslation } from "react-i18next";

const Work = () => {
  const { t } = useTranslation();
  const workInfoData = [
    {
      image: PickMeals,
      title: t('work.workData.box1.title'),
      text: t('work.workData.box1.description'),
    },
    {
      image: ChooseMeals,
      title: t('work.workData.box2.title'),
      text: t('work.workData.box2.description'),
    },
    {
      image: DeliveryMeals,
      title: t('work.workData.box3.title'),
      text: t('work.workData.box3.description'),
    },
  ];
  return (
    <div className="work-section-wrapper" id="work">
      <div className="work-section-top">
        <p className="primary-subheading">{t('work.subtitle')}</p>
        <h1 className="primary-heading">{t('work.title')}</h1>
        <p className="primary-text">
        {t('work.description')}
        </p>
      </div>
      <div className="work-section-bottom">
        {
            workInfoData.map((data) => (
                <div className="work-section-info" key={data.title}>
                    <div className="info-boxes-img-container">
                        <img src={data.image} alt="" />
                    </div>
                    <h2>{data.title}</h2>
                    <p>{data.text}</p>
                </div>
            ))
        }
      </div>
    </div>
  );
};

export default Work;
