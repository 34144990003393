import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import enGB from './locales/en-GB.json'
import frFR from './locales/fr-FR.json'

const defaultLanguage = 'fr-FR'

export const defaultNamespace = 'default'


export const resources = {
    'en-GB': {
    [defaultNamespace]: enGB,
  },
  'fr-FR': {
    [defaultNamespace]: frFR,
  },
}

export const changeLanguage = async language => {
    await i18n.changeLanguage(language)
  }

i18n.use(initReactI18next)
.use(LanguageDetector)
.init({
  defaultNS: defaultNamespace,
  ns: [defaultNamespace],  
  resources,
  lng: defaultLanguage,
  fallbackLng: defaultLanguage,
  interpolation: {
    escapeValue: false,
  },
})