import React, { useState } from 'react';
import Logo from '../Assets/Logo.svg';
import { BsTwitterX } from 'react-icons/bs';
import { SiLinkedin } from 'react-icons/si';
import { BsYoutube } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';
import { useTranslation } from "react-i18next";
import i18n from 'i18next';

const Footer = () => {
    const { t } = useTranslation();

    const [lang, setLang] = useState("fr-FR");

    const langChange = (e) => {
        const newLang = e.target.value;
        setLang(newLang);
        localStorage.setItem("lang", newLang);
        i18n.changeLanguage(newLang);
    }

  return (
  <div className='footer-wrapper' >
    <div className='footer-section-one' >
        <div className='footer-logo-container' >
            <img src={Logo} alt='' />
        </div>
        <div className='footer-icons'>
            <BsTwitterX />
            <SiLinkedin />
            <BsYoutube />
            <FaFacebookF />
        </div>
        <div className='footer-icons'>
            <select 
                onChange={langChange}
                name="lang"
                value={lang}
                >   
                    <option defaultChecked value='fr-FR'>FR</option>
                    <option value='en-GB'>EN</option>
            </select>
        </div>
    </div>
    <div className='footer-section-two'>
        <div className='footer-section-columns'>
            <span>{t('footer.link1')}</span>
            <span>{t('footer.link2')}</span>
            <span>{t('footer.link3')}</span>
            <span>{t('footer.link4')}</span>
            <span>{t('footer.link5')}</span>
            <span>{t('footer.link6')}</span><br/>
            <span>{t('footer.copyright')}</span>   
        </div>
        <div className='footer-section-columns' >
            <span>+237 651 97 76 89</span>
            <span>hello@food.com</span>
            <span>press@food.com</span>
            <span>contact@food.com</span>
        </div>
        <div className='footer-section-columns'>
            <span>{t('footer.terms')}</span>
            <span>{t('footer.privacy')}</span>
        </div>
    </div>
  </div>
  );
};


export default Footer;
