import React from 'react'
import Navbar from './Navbar';
import BannerBackBackground from '../Assets/home-banner-background.png';
import BannerImage from '../Assets/home-banner-image.png';
import { FiArrowRight } from 'react-icons/fi';
import { useTranslation } from "react-i18next";

const Home = () => {
    const { t } = useTranslation();
  return (
  <div className='home-container' id='home'>
    <Navbar/>
    <div className='home-banner-container'>
        <div className='home-bannerImage-container'>
            <img src={BannerBackBackground} alt='' />
        </div>
        <div className='home-text-section'>
            <h1 className='primary-heading'>
            {t('home.title')}
            </h1>
            <p className='primary-text'>
            {t('home.description')}
            </p>
            <button className='secondary-button'>
            {t('home.button')} <FiArrowRight />
            </button>
        </div>
        <div className='home-image-container'>
            <img src={BannerImage} alt='' />
        </div>
    </div>
  </div>
  );
};

export default Home;
