import React from "react";
import ProfilePic from "../Assets/john-doe-image.png";
import { AiFillStar } from "react-icons/ai";
import { useTranslation } from "react-i18next";

const Testimonial = () => {
  const { t } = useTranslation();
  return (
    <div className="work-section-wrapper" id="testimonial">
      <div className="work-section-top">
        <p className="primary-subheading">{t('testimonials.subtitle')}</p>
        <h1 className="primary-heading">{t('testimonials.title')}</h1>
        <p className="primary-text">
        {t('testimonials.description')}
        </p>
      </div>
      <div className="testimonial-section-bottom">
        <img src={ProfilePic} alt="" />
        <p>
        {t('testimonials.testimonial')}
        </p>
        <div className="testimonials-stars-container" >
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
        </div>
        <h2>John Doe</h2>
      </div>
    </div>
  );
};

export default Testimonial;
