import React from "react";
import AboutBackground from "../Assets/about-background.png";
import AboutBackgroundImage from "../Assets/about-background-image.png";
import { BsFillPlayCircleFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  return (
    <div className="about-section-container" id="about">
      <div className="about-background-image-container">
        <img src={AboutBackground} alt="" />
      </div>
      <div className="about-section-image-container">
        <img src={AboutBackgroundImage} alt="" />
      </div>
      <div className="about-section-text-container">
        <p className="primary-subheading">{t('about.subtitle')}</p>
        <h1 className="primary-heading">
        {t('about.title')}
        </h1>
        <p className="primary-text">
        {t('about.description')}
        </p>
        <p className="primary-text">
        {t('about.content')}
        </p>
        <p className="about-buttons-container">
          <button className="secondary-button">{t('about.button1')}</button>
          <button className="watch-video-button">
            {" "}
            <BsFillPlayCircleFill /> {t('about.button2')}
          </button>
        </p>
      </div>
    </div>
  );
};

export default About;
