import React from 'react';
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();
  return <div className='contact-page-wrapper' id='contact'>
    <h1 className='primary-subheading'>{t('contact.subtitle')}</h1>
    <h1 className='primary-heading'>{t('contact.title')}</h1>
    <div className='contact-form-container'>
        <input type='text' placeholder={t('contact.adresse')} />
        <button className='secondary-button'>{t('contact.button')}</button>
    </div>
  </div>
};

export default Contact;
