import React, { useState } from "react";
import Logo from "../Assets/Logo.svg";
import { BsCart2 } from "react-icons/bs";
import { HiOutlineBars3 } from "react-icons/hi2";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import WorkIcon from "@mui/icons-material/Work";
import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);
  const menuOptions = [
    {
      text: t('navbar.home'),
      icon: <HomeIcon />,
      anchor: "#home",
    },
    {
      text: t('navbar.about'),
      icon: <InfoIcon />,
      anchor: "#about",
    },
    {
        text: t('navbar.work'),
        icon: <WorkIcon />,
        anchor: "#work",
      },
    {
      text: t('navbar.testimonial'),
      icon: <CommentRoundedIcon />,
      anchor: "#testimonial",
    },
    {
      text: t('navbar.contact'),
      icon: <PhoneRoundedIcon />,
      anchor: "#contact",
    },
    {
      text: t('navbar.cart'),
      icon: <ShoppingCartRoundedIcon />,
      anchor: "#cart",
    },
  ];

  return (
    <nav>
      <div className="nav-logo-container">
        <img src={Logo} alt="" />
      </div>
      <div className="navbar-links-container">
        <a href="#home">{t('navbar.home')}</a>
        <a href="#about">{t('navbar.about')}</a>
        <a href="#work">{t('navbar.work')}</a>
        <a href="#testimonial">{t('navbar.testimonial')}</a>
        <a href="#contact">{t('navbar.contact')}</a>
        <a href="#cart">
          <BsCart2 className="nav-cart-icon" />
        </a>
        <button className="primary-button">{t('navbar.button')}</button>
      </div>
      <div className="navbar-menu-container">
        <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
      </div>
      <Drawer open={openMenu} onClose={() => setOpenMenu(false)} 
      anchor='right'>
        <Box sx={{ width: 250 }}
        role="presentation"
        onClick={() => setOpenMenu(false)}
        onKeyDown={() => setOpenMenu(false)}
        >
            <List>                
                {menuOptions.map((item) => (
                    <ListItem key={item.text} disablePadding >
                        <ListItemButton href={item.anchor}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
      </Drawer>
    </nav>
  );
};

export default Navbar;
